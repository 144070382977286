<template>
   <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        套餐管理
      </template>
      <template #input>
        <!-- <a-select
          placeholder="活动状态"
          v-model="queryParams.activeStatus"
          allowClear
        >
          <a-icon
            slot="suffixIcon"
            type="caret-down"
          />
          <a-select-option :value="1">未开始</a-select-option>
          <a-select-option :value="2">进行中</a-select-option>
          <a-select-option :value="3">已结束</a-select-option>
        </a-select> -->
        <a-select
          placeholder="发布状态"
          v-model="queryParams.status"
          allowClear
        >
          <a-icon
            slot="suffixIcon"
            type="caret-down"
          />
          <a-select-option :value="1">
            上架
          </a-select-option>
          <a-select-option :value="0">
            下架
          </a-select-option>
        </a-select>
        <a-input v-model="queryParams.keyword" placeholder="请输入活动名称" allowClear/>
        <a-button
          type="primary"
          class="btn"
          @click="onSearch" 
        >搜索</a-button>
        <a-button class="all_boder_btn btn" @click="onReset">重置</a-button>
        <a-button
          class="all_boder_btn"
          @click="toDetail(1)"
        >新建</a-button>
      </template>
    </HeaderBox>

      <a-table 
        class="table-template"
        :rowKey="item=>item.packageId"
        :columns="columns" 
        :loading="loading"
        :data-source="tableData" 
        :locale="{emptyText: '暂无数据'}" 
        @change="onPage"
        :pagination="{
          total:total,
          current:queryParams.pageNum,  
          defaultpageSize:queryParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
            return `共${total}条`
          }
        }">
        <!-- 序号 -->
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }}
      </template>
      
      <!-- 活动时间 -->
      <template
        slot="timeType"
        slot-scope="item"
      >
        <span v-if="item.timeType == 1">不限</span>
        <div v-else>
          <p>{{item.startTime}}</p>
          <p>{{item.endTime}}</p>
        </div>
      </template>

      <template slot="show" slot-scope="item,row">
        <template v-if="!(row.first==1&&row.last==1)">
          <a v-if="row.first!=1" @click="courseCategoryId=row.courseCategoryId;moveData(1)">前移</a>
          <span v-if="row.first==0 && row.last==0"> | </span>
          <a v-if="row.last!=1" @click="courseCategoryId=row.courseCategoryId;moveData(2)">后移</a>
        </template>
        <span v-else>-</span>
      </template>

      <!-- 活动状态 -->
      <template
        slot="activeStatus"
        slot-scope="item"
      >
       <span class="state_all" :class="item==2 ? 'state_color_green' : item==3 ? 'state_color_red' : ''">{{item==1?'未开始' : item==2? '进行中' : '已结束'}}</span>
      </template>
      <!-- 发布状态 -->
      <template
        slot="status"
        slot-scope="item"
      >
        <span :class="item ? 'state_color_green' : 'state_color_red'">{{item ? '上架' : '下架'}}</span>
      </template>

      <!-- 操作 -->
      <template slot="operation" slot-scope="item,row">
        <span class="blueText">
          <span @click="onStop(row)" v-show="row.activeStatus!=1">{{item.status ? '下架' : '上架'}} | </span>
          <span @click="toDetail(2,item.packageId)">编辑</span> |
          <span @click="deleteFilling(item.packageId)">删除</span>
        </span>
      </template>
    </a-table>

    <!-- <a-modal
      v-model="create"
      width='440px'
      title="选择活动类型"
      @ok="createOk"
      :centered='true'
      :closable='false'
    >
      <div class="class-box">
        <div
          class="classify"
          @click="onSelect(1)"
          :class="{ 'classblue': isSelect == '1' }"
        >拼团抢购</div>
        <div
          class="classify"
          @click="onSelect(2)"
          :class="{ 'classblue': isSelect == '2' }"
        >套餐搭配</div>
        <div
          class="classify"
          @click="onSelect(3)"
          :class="{ 'classblue': isSelect == '3' }"
        >限时特惠</div>
      </div>
    </a-modal> -->

    <a-modal
      v-model="isDelete"
      width='400px'
      title="删除"
      @ok="deleteOk"
      :centered='true'
      :closable='false'
    >
      <div class="tips">{{isBuy?'当前的活动已有用户购买，该活动不可删除。':'是否确认删除该活动？'}}</div>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "活动名称",
    align: "center",
    dataIndex: "packageName",
  },
  // {
  //   title: "活动时间",
  //   align: "center",
  //   scopedSlots: {
  //     customRender: "timeType",
  //   },
  // },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "销量",
    align: "center",
    dataIndex: "salesVolume",
  },
  // {
  //   title: "活动状态",
  //   align: "center",
  //   dataIndex: "activeStatus",
  //   scopedSlots: {
  //     customRender: "activeStatus",
  //   },
  // },
  {
    title: "发布状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: {
      customRender: "status",
    },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {
    HeaderBox,
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      isSelect: "",
      isBuy: false,
      // create: false,
      loading:false,
      isDelete: false,
      columns,
      tableData:[],
      total: 0,
      queryParams: {
        activeStatus:undefined,  // 活动状态1.未开始 2.进行中 3.已结束
        status: undefined,  // 发布状态1.上架 0.下架
        keyword:'',
        pageNum: 1, //页码
        pageSize: 10, // 页数
      },
      packageId:''
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getManageList()
    },
    onReset(){
      this.queryParams.activeStatus = undefined,  // 活动状态1.未开始 2.进行中 3.已结束
      this.queryParams.status = undefined,  // 发布状态1.上架 0.下架
      this.queryParams.keyword = ''
      this.getManageList()
    },
    // 高级检索
    onSearch(){
      this.queryParams.pageNum = 1
      this.getManageList()
    },
    // createOk(e) {
    //   this.create = false;
    //   this.isSelect = "";
    // },
    toDetail(operation, id) {
      this.$router.push({ path: "/admin/PromotionManage/packageDetail", query: { operation:operation,id: id } })
    },
    onSelect(e) {
      this.isSelect = e;
    },
    deleteOk(e) {
      this.isDelete = false;
      this.deleteData(this.packageId)
    },
    deleteFilling(id) {
      this.packageId = id
      this.isDelete = true;
    },
    // 控制 上/下架
    onStop(e){
      this.loading = true
      this.$ajax({
        method: 'put',
        url:"/hxclass-management/packages/change-status",
        params: {
          modifier: this.$store.state.userInfo.id,
          packageId: e.packageId,
          status: e.status ? 0 : 1
        }
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 删除标签
    deleteData(id) {
      this.$ajax({
        url: '/hxclass-management/packages/' + id,
        method: "DELETE",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success('删除成功');
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/packages/",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList()
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;

  .ant-select {
    width: 124px !important;
  }

  .btn {
    margin: 0 24px 14px 0;
  }

  .exam-name {
    margin-top: 16px;

    .tag {
      margin-right: 2px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 14px;
    }

    /deep/.ant-select {
      width: 240px !important;
    }
  }
}
.class-box {
  display: flex;
  justify-content: space-between;
  .classify {
    width: 30%;
    height: 100px;
    border: 1px solid #666666;
    color: #000000;
    line-height: 100px;
    text-align: center;
  }
  .classblue {
    border-color: #2b6ed4;
    color: #2b6ed4;
  }
}
.tips {
  font-size: 16px;
  color: #000000;
  text-align: center;
}
/deep/.ant-modal-title {
  color: #000000;
  text-align: center;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
}
</style>
